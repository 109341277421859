import React from "react";
import "./featured.css";

const Featured = () => {
  return (
    <div className="featured">
      <h1 className="featured__text">Featured Tools</h1>
      <div className="featured-sites-container">
        <div className="site-item">
          <div className="feat-card">
            <div className="feat-card__content">
              <div className="fcard-text featname">VisuAlgo.net</div>
              <div className="fcard-text featdesc">
                VisuAlgo gives helpful visuals and walkthroughs for many data
                structures and algorithms.
              </div>
              <div className="fcard-text fbutton">
                  <button className="button-name" onClick={() => window.open("https://visualgo.net/en", "_blank")}>Visit Site</button>
                
              </div>
            </div>
          </div>
        </div>
        <div className="site-item">
          <div className="feat-card">
            <div className="feat-card__content">
              <div className="fcard-text featname">UIverse.io</div>
              <div className="fcard-text featdesc">
                Provides open source html/css components to use in your web
                application.
              </div>
              <div className="fcard-text fbutton">
                <button
                  className="button-name"
                  onClick={() => window.open("https://uiverse.io/", "_blank")}
                >
                  Visit Site
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="site-item">
          <div className="feat-card">
            <div className="feat-card__content">
              <div className="fcard-text featname">Levels.fyi</div>
              <div className="fcard-text featdesc">
                Levels.fyi compares salaries of different jobs in the tech
                world. Also a resource to find internships.
              </div>
              <div className="fcard-text fbutton">
                  <button className="button-name" onClick={() => window.open("https://www.levels.fyi/?compare=Cerner,Garmin,Oracle&track=Software%20Engineer", "_blank")}>Visit Site</button>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Featured;
