import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="fcontain">
      <div className="grid-container">
        <div className="grid-item">
          {" "}
          <p className="cdate">Copyright © {currentYear} WebToolSearch</p>
        </div>
        <div className="grid-item">
          <div className="f-contact-text"><Link className="donate-lnk" to="/feedback">
            Email: Logan@WebToolSearch.com
          </Link></div>
        </div>
        <div className="grid-item"><Link className="donate-lnk" to="/donate">
            Donate
          </Link></div>
        
      </div>
    </footer>
  );
};

export default Footer;
