import React from "react";
import "./heading.css";
import computer from "../assets/computer.png";
import { Link } from "react-router-dom";

const Heading = () => {
  return (
    <div className="homepage__heading">
      <div className="heading__left">
        <div className="heading__text">
          <h1 className="heading__hero">
            All your helpful web tools in one place
          </h1>
          <Link className="shadow__btn" to="/">
            Browse All Tools
          </Link>
        </div>
      </div>
      <div className="heading__right">
        <div className="heading__logo">
          <img src={computer} alt="computer-logo" />
        </div>
      </div>
    </div>
  );
};

export default Heading;
