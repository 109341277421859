import React from "react";
import { Link } from "react-router-dom";
import "./docsidebar.css";

const DocSidebar = () => {
  return (
    <div className="sidebar-container">
      <div className="sidebar-heading">Documentation</div>
      <div className="sidebar-links">
        <nav>
          <Link to="/cpp">
            <button className="sidebar-button-link">C++</button>
          </Link>
        </nav>
        <nav>
          <Link to="/cpp">
            <button className="sidebar-button-link">Python</button>
          </Link>
        </nav>
        <nav>
          <Link to="/cpp">
            <button className="sidebar-button-link">Javascript</button>
          </Link>
        </nav>
        <nav>
          <Link to="/cpp">
            <button className="sidebar-button-link">C</button>
          </Link>
        </nav>
        <nav>
          <Link to="/cpp">
            <button className="sidebar-button-link">Ruby</button>
          </Link>
        </nav>
        <nav>
          <Link to="/cpp">
            <button className="sidebar-button-link">HTML</button>
          </Link>
        </nav>
      </div>
    </div>
  );
};

export default DocSidebar;
