import image1 from "./assets/tool-imgs/angry-tools.png";
import image2 from "./assets/tool-imgs/devdocs-img.png";
import image3 from "./assets/tool-imgs/neu-img.png";
import image4 from "./assets/tool-imgs/uiverse-img.png";
import image5 from "./assets/tool-imgs/c2f-img.png";
import image6 from "./assets/tool-imgs/favi-img.png";
import image7 from "./assets/tool-imgs/colorhunt-img.png";
import image8 from "./assets/tool-imgs/cssportal-img.png";
import image9 from "./assets/tool-imgs/levels-img.png";
import image10 from "./assets/tool-imgs/codepen-img.png";
import image11 from "./assets/tool-imgs/animatestyle-img.png";
import image12 from "./assets/tool-imgs/cleanup-img.png";
import image13 from "./assets/tool-imgs/resumeworded-img.png";
import image14 from "./assets/tool-imgs/unbound-img.png";
import image15 from "./assets/tool-imgs/bgjar-img.png";
import image16 from "./assets/tool-imgs/flaticons-img.png";
import image17 from "./assets/tool-imgs/fluttergems-img.png";
import image18 from "./assets/tool-imgs/geeksforgeeks-img.png";
import image19 from "./assets/tool-imgs/overthewire-img.png";
import image20 from "./assets/tool-imgs/animista-img.png";
import image21 from "./assets/tool-imgs/visualgo-img.png";
import image22 from "./assets/tool-imgs/alternativeto-img.png";
import image23 from "./assets/tool-imgs/diagrams-img.png";
import image24 from "./assets/tool-imgs/codeacademy-img.png";
import image25 from "./assets/tool-imgs/namingmagic-img.png";
import image26 from "./assets/tool-imgs/durable-img.png";
import image27 from "./assets/tool-imgs/make-img.png";
import image28 from "./assets/tool-imgs/synthesia-img.png";
import image29 from "./assets/tool-imgs/midjourney-img.png";
import image30 from "./assets/tool-imgs/transition-img.png";
import image31 from "./assets/tool-imgs/neeva-img.png";
import image32 from "./assets/tool-imgs/bito-img.png";
import image33 from "./assets/tool-imgs/voiceai-img.png";



const websites = [
  {
    name: "AngryTools.com",
    img: image1,
    alt: "angry-tools-img",
    heading:
      "Features many tools including: Gradient Generator, Image Crop, Image Color Picker, Flexbox Generator, Grid Layout Generator, Bootstrap Editor, and more.",
    link: "https://angrytools.com/",
    price: "free",
    searchtags:
      " 'free' 'web development' css tools css generators angrytools.com free tool Gradient Generator Image Crop Image Color Picker Flexbox Generator Grid Layout Generator Bootstrap Editor",
  },
  {
    name: "DevDocs.io",
    img: image2,
    alt: "dev-docs-img",
    heading: "Documentation for a vast selection of programming languages",
    link: "https://devdocs.io/",
    price: "free",
    searchtags:
      "'free' 'learn' coding devdocs.io 'documentation' docs free docs css documentation javascript documentation",
  },
  {
    name: "Neumorphism.io",
    img: image3,
    alt: "neu-img",
    heading: "CSS generator for neumorphism UI - Creates soft-UI CSS code",
    link: "https://neumorphism.io/#e0e0e0",
    price: "free",
    searchtags:
      "'free' 'web development' html css custom neumorphism.io neu UI css generator neumorphism generator css code soft ui/ux ",
  },
  {
    name: "UIVerse.io",
    img: image4,
    alt: "uiverse-img",
    heading:
      "Open Source HTML/CSS elements to incorporate in your project or get ideas from. Buttons, Checkboxes, Toggle Switches, Cards, Loaders, Inputs",
    link: "https://uiverse.io/",
    price: "free",
    searchtags:
      "'free' 'web development' css generator custom elements custom button uiverse.io Ui/ux ui ux open source free open source css elements free css elements free css button checkbox toggle switch card loader input html css html/css css buttons checkboxes toggle switches cards css loaders css inputs",
  },
  {
    name: "CSS to Flutter Generator",
    img: image5,
    alt: "c2f-img",
    heading:
      "Changes css code to flutter code to make flutter development more efficient",
    link: "https://drawcall.github.io/c2f/",
    price: "free",
    searchtags: "'free' css to flutter c2f dart code 'mobile development'",
  },
  {
    name: "Favicon.cc",
    img: image6,
    alt: "favi-img",
    heading: "Create a favicon for your website",
    link: "https://www.favicon.cc/",
    price: "free",
    searchtags: "'free' favicon.cc 'web development' favicon creator icon ",
  },
  {
    name: "ColorHunt.io",
    img: image7,
    alt: "colorhunt-img",
    heading: "Create color palletes for artists and developers",
    link: "https://colorhunt.co/",
    price: "free",
    searchtags:
      "'free' colorhunt.io colors color pallete artist ui ux ui/ux hex colors color hunt 'web development'",
  },
  {
    name: "Cssportal.com",
    img: image8,
    alt: "cssportal-img",
    heading:
      "css tools: border radius generator, box shadow generator, button generator, gradient, scrollbar, layout, and more.",
    link: "https://www.cssportal.com/css-generators.php",
    price: "free",
    searchtags:
      "'free' 'web development' cssportal.com css portal css tools: border radius generator, box shadow generator, button generator, gradient, scrollbar, layout, and more. css code generator ui ux ui/ux design html web dev css dev",
  },
  {
    name: "Levels.fyi",
    img: image9,
    alt: "levels-img",
    heading:
      "Compare careers/salaries, find internships in computer science and other fields",
    link: "https://www.levels.fyi/?compare=Cerner,Garmin,Oracle&track=Software%20Engineer",
    price: "free",
    searchtags:
      "'free' 'business' levels.fyi levels internships jobs 'career' salary salaries computer science software engineer jobs job search internship search",
  },
  {
    name: "CodePen.io",
    img: image10,
    alt: "codepen-img",
    heading:
      "In browser resource to build and test front end code for web development.",
    link: "https://codepen.io/",
    price: "free",
    searchtags:
      "'free' 'web development' codepen.io code css html javascript build and deploy websites in browser web dev in browser html in browser environment 'front end' development",
  },
  {
    name: "Animate.style",
    img: image11,
    alt: "animate-img",
    heading: "library of free css animations",
    link: "https://animate.style/",
    price: "free",
    searchtags:
      "'free' animate.style css animations css animate free css styles code generator 'web development'",
  },
  {
    name: "Cleanup.pictures",
    img: image12,
    alt: "cleanuppictures-img",
    heading: "Remove unwanted areas of photos quickly, remove background, etc",
    link: "https://cleanup.pictures/#",
    price: "freemium",
    searchtags:
      "'freemium' 'other' cleanup.pictures clean up pictures remove background of pictures background remove photo editing refinement",
  },
  {
    name: "Resume Worded",
    img: image13,
    alt: "resumeworded-img",
    heading: "Online AI tool that gives you feedback on resume/linkedIn",
    link: "https://resumeworded.com/",
    price: "freemium",
    searchtags:
      "'freemium' 'career' resumeworded.com resume worded resume help resume critique career job internships interviewing resume feedback linkedin help ai tool resume grader",
  },
  {
    name: "Unbound AI",
    img: image14,
    alt: "unboundai-img",
    heading: "Creates product photos and content to promote your business",
    link: "https://www.unboundcontent.ai/",
    price: "freemium",
    searchtags:
      "'freemium' 'business' unbound ai unboundai ai tool create product photos promote marketing ai product photos content creation ",
  },
  {
    name: "BGJar.com",
    img: image15,
    alt: "bgjar-img",
    heading: "free svg backgrounds",
    link: "https://bgjar.com/",
    price: "free",
    searchtags:
      "'free' svg background bgjar.com web development 'front end' 'web development' css html javascript free web animations",
  },
  {
    name: "FlatIcon.com",
    img: image16,
    alt: "flaticon-img",
    heading:
      "millions of free vector icons for your project, in the formats PNG, SVG, EPS, PSD, and CSS",
    link: "https://www.flaticon.com/",
    price: "freemium",
    searchtags:
      "'freemium' 'web development' flaticon.com flaticons flat icon .com icons flat icons png svg eps psd css web development vector icons and stickers",
  },
  {
    name: "FlutterGems.dev",
    img: image17,
    alt: "fluttergems-img",
    heading: "The best dart/flutter packages to use in your projects!",
    link: "https://fluttergems.dev/",
    price: "free",
    searchtags:
      "'free' flutter gems fluttergems.dev dart flutter packages dart packages best dart packages best flutter packages 'mobile development'",
  },
  {
    name: "GeeksForGeeks.org",
    img: image18,
    alt: "geeksforgeeks-img",
    heading:
      "Vast array of resources to get better at computer science topics and more. Explains data structures. Has many courses/articles to choose from",
    link: "https://www.geeksforgeeks.org/",
    price: "free",
    searchtags:
      "'free' geeksforgeeks.org geeks for geeks 'computer science help' degree help degree aid programming languages 'programming help' topics courses articles cs resource",
  },
  {
    name: "OverTheWire Wargames",
    img: image19,
    alt: "overthewire-img",
    heading:
      "CyberSecurity themed challenges/games to improve cybersecurity skills",
    link: "https://overthewire.org/wargames/",
    price: "free",
    searchtags:
      "'free' 'cybersecurity' overthewire.org wargames over the wire cyber security improvement challenges get better",
  },
  {
    name: "Animista.net",
    img: image20,
    alt: "animista-img",
    heading: "Helps build css animations - generates code for you",
    link: "hhttps://animista.net/",
    price: "free",
    searchtags:
      "'free' 'css' 'web development' 'front end' animista.net css animations code generation html javascript css",
  },
  {
    name: "VisuAlgo",
    img: image21,
    alt: "visualgo-img",
    heading:
      "Visualizes data structures and algorithms for better understanding. MST, Sorting, Hash Table, etc.",
    link: "https://visualgo.net/en",
    price: "free",
    searchtags:
      "'free' 'data structures' 'algorithms' visualgo.net sorting linked list minimum spanning tree mst binary heap hash table visual learning diagrams graphs binary search tree suffix tree single source shortest path",
  },
  {
    name: "AlternativeTo.net",
    img: image22,
    alt: "alternativeto-img",
    heading: "Find similar software and tools to the ones you already know.",
    link: "https://alternativeto.net/",
    price: "free",
    searchtags:
      "'free' software alternatives alternativeto.net recommended software tools find tools find software find alternatives ",
  },
  {
    name: "Diagrams.net",
    img: image23,
    alt: "diagrams-img",
    heading: "Create diagrams - easily shareable and secure.",
    link: "https://www.diagrams.net/",
    price: "free",
    searchtags:
      "'free' 'other' diagrams.net diagrams create diagrams share within teams make diagrams visualize ",
  },
  {
    name: "Codecademy.com",
    img: image24,
    alt: "codecademy-img",
    heading: "Learn how to code - beginner friendly",
    link: "https://www.codecademy.com/",
    price: "freemium",
    searchtags:
      "'freemium' 'learn' coding codeacademy learn to code development subscription code academy",
  },
  {
    name: "NamingMagic",
    img: image25,
    alt: "namingmagic-img",
    heading: "Gives you ideas for names of a domain for your new business",
    link: "https://www.namingmagic.com/",
    price: "free",
    searchtags:
      "'free' business 'career' 'ai' 'business' namingmagic.com naming magic name of a business name automation",
  },
  {
    name: "Durable.co",
    img: image26,
    alt: "durable-img",
    heading: "AI Website builder - free to get ideas. Costs to deploy.",
    link: "https://durable.co/",
    price: "freemium",
    searchtags:
      "'freemium' website builder 'ai' durable.co 'web development' 'nocode' get ideas for website 'front-end'",
  },
  {
    name: "Make.com",
    img: image27,
    alt: "make-img",
    heading: "Build and Automate systems and apps in a visual workspace",
    link: "https://www.make.com/en",
    price: "freemium",
    searchtags:
      "'freemium' 'automate' automation make.com 'nocode' build and automate systems visualize ",
  },
  {
    name: "Synthesia.io",
    img: image28,
    alt: "synthesia-img",
    heading: "AI video creation from plain text",
    link: "https://www.synthesia.io/",
    price: "$30/month",
    searchtags:
      "'paid' video creation 'video' 'ai' video automation synthesia.io synesthisia synethesia",
  },
  {
    name: "Midjourney.com",
    img: image29,
    alt: "midjourney-img",
    heading: "AI art generation",
    link: "https://midjourney.com/home/?callbackUrl=%2Fapp%2F",
    price: "$8/month is cheapest",
    searchtags:
      "'paid' 'ai' art 'other' ai art midjourney.com midjourney art generation ai art generation ",
  },
  {
    name: "Transition.style",
    img: image30,
    alt: "transition-img",
    heading:
      "Copy and paste css transitions. Good place to get inspiration for other animations as well.",
    link: "https://www.transition.style/",
    price: "free",
    searchtags:
      "'free' transitions 'css' 'web development' animations html javascript transition.css transition.style styles css styles css animations ",
  },
  {
    name: "Neeva AI Search",
    img: image31,
    alt: "neeva-img",
    heading: "Search using AI. Provides protection from trackers.",
    link: "https://neeva.com/",
    price: "freemium",
    searchtags: "'freemium' 'ai' 'other' search ai search neeva chatgpt artificial intelligence like google search with protection",
  },
  {
    name: "BITO VScode extension",
    img: image32,
    alt: "bito-img",
    heading: "Use ChatGPT to write and edit your code within virtual studio code.",
    link: "https://marketplace.visualstudio.com/items?itemName=Bito.Bito",
    price: "free",
    searchtags: "'free' 'ai' virtual studio code extension vscode extension bito.ai bito ai chatgpt ai code generation 'web development' 'mobile development' ",
  },
  {
    name: "Eleven Labs Voice AI",
    img: image33,
    alt: "voiceai-img",
    heading: "Realistic AI voice reads your text. Sounds just like someone talking. Different voices to choose from. ",
    link: "https://beta.elevenlabs.io/",
    price: "freemium",
    searchtags: "'freemium' 'ai' 'other' eleven labs beta.elevenlabs.io voice ai ai voice text to speech ai ai text to speech ai speaking vocal ai prime voice ai the most realistic ai voice realistic voice ",
  },
  
];

export default websites;
