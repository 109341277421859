import React from "react";
import ContactForm from "../../components/ContactForm";
import Footer from "../../components/Footer";
import "./feedback.css";

const Feedback = () => {
  return (
    <div className="mcontain">
      <div className="feedback-main">
        <ContactForm />
      </div>
      <Footer />
    </div>
  );
};

export default Feedback;
