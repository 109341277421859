import React from "react";
import DocSidebar from "../../components/Documentation/DocSidebar";
import "./documentation.css";

const Documentation = () => {
  return (
    <div className="documentation-page">
      <div className="documentation-sidebar">
        <DocSidebar />
      </div>
      <div className="doc-content">
        <h1>Learn other programming languages!</h1>
        <h2>
          Click on a language on the left sidebar to find helpful documentation.
        </h2>
      </div>
    </div>
  );
};

export default Documentation;
