import React from "react";
import "./searchtools.css";
import { useState } from "react";
import SiteCard from "./SiteCard";
import websites from "../../src/websites.js";

function createCard(site) {
  return (
    <SiteCard
      key={site.id}
      name={site.name}
      img={site.img}
      alt={site.alt}
      heading={site.heading}
      link={site.link}
      tags={site.tags}
      price={site.price}
      searchtags={site.searchtags}
    />
  );
}

const SearchTools = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("");
  const [activeButton, setActiveButton] = useState("");
  const [displayedTool, setDisplayedTool] = useState(null);

  const handleFilterClick = (filterValue) => {
    setFilter(filterValue);
    setActiveButton(filterValue);
    setDisplayedTool(null);
  };

  const handleRandomButtonClick = () => {
    setDisplayedTool(websites[Math.floor(Math.random() * websites.length)]);
  };
  return (
    <div className="searchtools__parent">
      <div className="searchbar">
        <input
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
          type="text"
          name="text"
          className="input"
          placeholder="Search for a tool"
        ></input>
      </div>
      <div className="search__filter-buttons">
        <div className="filters-top">
          <button
            className={`button2 ${activeButton === "'web development'" ? "active" : ""}`}
            onClick={() => handleFilterClick("'web development'")}
          >
            Web Dev
          </button>
          <button
            className={`button2 ${activeButton === "'mobile development'" ? "active" : ""}`}
            onClick={() => handleFilterClick("'mobile development'")}
          >
            Mobile Dev
          </button>
          <button
            className={`button2 ${activeButton === "'career'" ? "active" : ""}`}
            onClick={() => handleFilterClick("'career'")}
          >
            Career
          </button>
          <button
            className={`button2 ${activeButton === "'ai'" ? "active" : ""}`}
            onClick={() => handleFilterClick("'ai'")}
          >
            AI Tools
          </button>
          <button
            className={`button2 ${activeButton === "'other'" ? "active" : ""}`}
            onClick={() => handleFilterClick("'other'")}
          >
            Other
          </button>
        </div>
        <div className="filter-special">
          <button
            className={`button2 ${activeButton === "'free'" ? "active" : ""}`}
            onClick={() => handleFilterClick("'free'")}
          >
            Free
          </button>
          <button
            className={`button2 ${activeButton === "freemium" ? "active" : ""}`}
            onClick={() => handleFilterClick("freemium")}
          >
            Freemium
          </button>
          <button
            className={`button2 ${activeButton === "paid" ? "active" : ""}`}
            onClick={() => handleFilterClick("paid")}
          >
            Paid
          </button>
          <button
            className={`button2 ${activeButton === "" ? "active" : ""}`}
            onClick={() => handleFilterClick("")}
          >
            ALL
          </button>
          <button
            className="button2 random-button"
            onClick={handleRandomButtonClick}
          >
            Find Random Tool
          </button>
        </div>
      </div>
      {displayedTool && (
        <div className="toollist-container">
          <SiteCard
            key={displayedTool.id}
            name={displayedTool.name}
            img={displayedTool.img}
            alt={displayedTool.alt}
            heading={displayedTool.heading}
            link={displayedTool.link}
            tags={displayedTool.tags}
            price={displayedTool.price}
            searchtags={displayedTool.searchtags}
          />
        </div>
      )}
      <div className="toollist-container">
        {websites
          .filter((val) => {
            if (searchTerm === "" && filter === "") {
              return val;
            } else if (
              val.searchtags.toLowerCase().includes(searchTerm.toLowerCase()) &&
              val.searchtags.toLowerCase().includes(filter.toLowerCase())
            ) {
              return val;
            }
          })
          .sort(() => Math.random() - 0.5)
          .map(createCard)}
      </div>
    </div>
  );
};

export default SearchTools;
