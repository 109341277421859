import React from "react";
import "./sitecard.css";
import downvote from "../assets/downvote.png";

const SiteCard = (props) => {
  const link = props.link;

  return (
    <div className="card">
      <div className="top-of-card">
        <div className="top-bar">
          <div className="three-dots">
            <div className="circle">
              <span className="red box"></span>
            </div>
            <div className="circle">
              <span className="yellow box"></span>
            </div>
            <div className="circle">
              <span className="green box"></span>
            </div>
          </div>
          <div className="site-name">{props.name}</div>
          <div className="helper"></div>
        </div>
        <div className="card-image">
          <img src={props.img} alt={props.alt} />
        </div>
      </div>
      <div className="card__content">
        <div className="site__description">{props.heading}</div>

        <div className="site__button-container">
          <div className="site__price-vote">
            
            <div className="site__voting-buttons">
              <button className="downvote-button">
                <img src={downvote} alt="downvote" />
              </button>
              <button className="upvote-button">
                <img src={downvote} alt="upvote" />
              </button>
            </div>
            <div className="site__price">
              <div className="site__price-box">
                Price: <span>{props.price}</span>
              </div>
            </div>
          </div>
          <div className="site__button">
            <a href={link} target="_blank" className="button" rel="noreferrer">
              <span className="button_top">Open {props.name} in a new tab</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteCard;
