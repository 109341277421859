import React from "react";
import Footer from "../../components/Footer";
import SearchTools from "../../components/SearchTools";
import "./Alltools.css";

const Alltools = () => {
  return (
    <div>
      <div className="alltools">
        <div className="alltools__content">
          <SearchTools />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Alltools;
