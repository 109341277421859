import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import About from "./pages/About/About";
import Donate from "./pages/Donate/Donate";
import Feedback from "./pages/Feedback/Feedback";
import Home from "./pages/Home/Home";
import NavbarToggle from "react-bootstrap/NavbarToggle";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";
import "./styles.css";
import Alltools from "./pages/Alltools/Alltools";
import Documentation from "./pages/Documentation/Documentation";
import Cpp from "./pages/Documentation/Cpp";
import PythonDoc from "./pages/Documentation/PythonDoc";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import logo from "../src/assets/WebSearchToolsLogo.png";

const App = () => {
  const initialOptions = {
    "client-id":
      "AR5dZ-N8UPDbuXu-gysTdW4KmhRHkeb6JY9CUalG-ooy1O5kDXAP9c9FcD-MhyYrhtzZLpgleJXgG_x9",
    currency: "USD",
    intent: "capture",
  };

  return (
    <PayPalScriptProvider options={initialOptions}>
      <BrowserRouter>
        <>
          <div>
            <Navbar
              className="fixed-top"
              bg="primary"
              variant="dark"
              expand="lg"
            >
              <Container>
                <div className="d-flex justify-content-start">
                  <Navbar.Brand>
                    <Nav.Link as={Link} to="/">
                      <img className="main-logo" src={logo} alt="logo-img" />
                    </Nav.Link>
                  </Navbar.Brand>
                </div>
                <NavbarToggle aria-controls="basic-navbar-nav" />
                <NavbarCollapse id="basic-navbar-nav">
                  <Nav className="me-auto">
                    <Nav.Link as={Link} to="/home" className="ml-3 link">
                      Home
                    </Nav.Link>
                    <Nav.Link as={Link} to="/" className="ml-3 link">
                      All Tools
                    </Nav.Link>
                    {/* <Nav.Link as={Link} to="/documentation" className="ml-3 link">
                      Documentation
                    </Nav.Link> */}
                    <Nav.Link as={Link} to="/about" className="ml-3 link">
                      About
                    </Nav.Link>
                    <Nav.Link as={Link} to="/feedback" className="ml-3 link">
                      Feedback
                    </Nav.Link>
                    <Nav.Link as={Link} to="/donate" className="ml-3 link">
                      Donate
                    </Nav.Link>
                  </Nav>
                </NavbarCollapse>
              </Container>
            </Navbar>
          </div>
        </>
        <div>
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/" element={<Alltools />} />
            <Route path="/documentation" element={<Documentation />} />
            <Route path="/about" element={<About />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/donate" element={<Donate />} />
            <Route path="/cpp" element={<Cpp />} />
            <Route path="/python" element={<PythonDoc />} />
          </Routes>
        </div>
      </BrowserRouter>
    </PayPalScriptProvider>
  );
};

export default App;
