import React from 'react';
import DocSidebar from '../../components/Documentation/DocSidebar';
import "./cpp.css";

const Cpp = () => {
  return (
    <div className='cpp-main'>
        <div className='cpp-sidebar'>
            <DocSidebar />
        </div>
        <div className='cpp-docs'>
            <h1>C++ documentation</h1>
        </div>
    </div>
  )
}

export default Cpp;