import React from 'react';
import Featured from '../../components/Featured';
import Footer from '../../components/Footer';
import Heading from '../../components/Heading';
import "./home.css";

const Home = () => {
  return (
    <div className='mcontain'>
      <div className="homepage">
          <Heading />
          <Featured />
      </div>
      <Footer />
    </div>
  )
}

export default Home;