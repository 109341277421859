import React from "react";
import "./paypaldono.css";

const PayPalDono = () => {
  return (
    <div className="donate-button-container">
      <form className="donate-button-form" action="https://www.paypal.com/donate" method="post" target="_top">
        <input type="hidden" name="business" value="2YGD82G3HZYK4" />
        <input type="hidden" name="no_recurring" value="0" />
        <input type="hidden" name="currency_code" value="USD" />
        <div className="donate-button-image">
            <input
              type="image"
              className="donate-button"
              src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
              border="0"
              name="submit"
              title="PayPal - The safer, easier way to pay online!"
              alt="Donate with PayPal button"
            />
        </div>
        <img
          alt=""
          border="0"
          src="https://www.paypal.com/en_US/i/scr/pixel.gif"
          width="1"
          height="1"
        />
      </form>
    </div>
  );
};

export default PayPalDono;
