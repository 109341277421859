import React from "react";
import Footer from "../../components/Footer";
import "./About.css";

const About = () => {
  return (
    <div className="mcontain">
      <div className="about-main">
        <div className="about-thissite">
          <h1>About WebToolSearch.com:</h1>
          <p>
            Do you have too many bookmarks of your favorite development tools? Do
            you have trouble finding good coding tools? This website was created
            to solve those problems. The "All Tools" tab has all the best coding
            tools from around the internet and you can search through to find new
            tools - including mostly free tools. {" "}
          </p>
          <p>
            Disclaimer: I am not the creator of these tools - if you see your tool on this website and you don't want it linked, I will promptly remove it.
          </p>
          {/* <div className="about-features">
            <h1>Features of the site:</h1>
            <ul>
              <li>
                Database of links to coding tools to search through in the "All
                Tools" tab.
              </li>
            </ul>
          </div> */}
          <div className="about-me">
            <h1>About Me:</h1>
            <p>
              My name is Logan. I am just a computer science student that wanted
              to make a helpful website for other developers. Hopefully it helps.
              If not, let me know in the feedback section and I'll make changes!
              Thanks for visiting, I appreciate it!
            </p>
          </div>{" "}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
