import React from "react";
import Footer from "../../components/Footer";
import PayPalDono from "../../components/PayPalDono";
import "./donate.css";

const Donate = () => {
  return (
    <div className="mcontain">
      <div className="donate-container">
        <div className="donate-container-inner">
          <h3 className="donate-text-header">
            Don't feel obligated to pay me anything, but if you want to support
            this website and help me out it would be awesome, you can donate by
            clicking this button:
          </h3>
          <PayPalDono />
          <h3 className="donate-text-charity">
            25% of your donation will go to a good cause. This month I am
            donating to:
          </h3>
          <div className="donate-card">
            <div className="donate-card__content">
              <div className="donate-name">Crisis Aid International</div>
              <button
                className="button-name"
                onClick={() =>
                  window.open("https://www.crisisaid.org/", "_blank")
                }
              >
                Visit Site
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Donate;
